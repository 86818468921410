import { NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { firebase } from '../../environments/environment';
import { AppComponent } from '../app.component';
import { AboutComponent } from '../components/about/about.component';
import { AfterschoolComponent } from '../components/afterschool/afterschool.component';
import { AtYourServiceComponent } from '../components/atyourservice/atyourservice.component';
import { FooterComponent } from '../components/footer/footer.component';
import { FailureComponent } from '../components/fundraiser/failure/failure.component';
import { FundraiserComponent } from '../components/fundraiser/fundraiser.component';
import { OfferComponent } from '../components/fundraiser/offer/offer.component';
import { PayComponent } from '../components/fundraiser/pay/pay.component';
import { SuccessComponent } from '../components/fundraiser/success/success.component';
import { ImpactComponent } from '../components/impact/impact.component';
import { LoginComponent } from '../components/login/login.component';
import { ManageComponent } from '../components/manage/manage.component';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { AuthGuard } from '../guards/auth.guard';
import { AuthService } from '../services/auth.service';
import { MaterialModule } from './material.module';
import { AppRoutingModule } from './routing.module';

@NgModule({
	declarations: [
		AppComponent,
		NavbarComponent,
		ImpactComponent,
		AtYourServiceComponent,
		AboutComponent,
		LoginComponent,
		ManageComponent,
		FundraiserComponent,
		OfferComponent,
		SuccessComponent,
		PayComponent,
		FailureComponent,
		FooterComponent,
		AfterschoolComponent,
	],
	imports: [
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		FlexLayoutModule,
		FormsModule,
		MaterialModule,
		ReactiveFormsModule,
		provideAuth(() => getAuth()),
		provideFirebaseApp(() => initializeApp(firebase.Cfg)),
		provideFirestore(() => getFirestore()),
		provideFunctions(() => getFunctions()),
		provideMessaging(() => getMessaging()),
		provideStorage(() => getStorage()),
	],
	providers: [AuthService, AuthGuard],
	bootstrap: [AppComponent],
})
export class AppModule {}
