import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FirestoreService } from 'src/app/services/firestore.service';
import { Partner } from '../../models/app.model';

@Component({
	selector: 'app-atyourservice',
	templateUrl: './atyourservice.component.html',
	styleUrls: ['./atyourservice.component.css'],
})
export class AtYourServiceComponent implements OnInit, OnDestroy {
	private partnerSubscription!: Subscription;

	public partner: Partner[] = [];

	constructor(public firestoreService: FirestoreService) {}

	ngOnInit() {
		this.partnerSubscription = this.firestoreService.getContributionDocumentsAsObservable().subscribe(result => {
			this.partner = result as Partner[];
		});
	}

	ngOnDestroy(): void {
		this.partnerSubscription.unsubscribe();
	}
}
