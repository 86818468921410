import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { Partner } from '../../models/app.model';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
	isLoggedIn: boolean = false;

	partner: Partner[] = [];

	constructor(public firestoreService: FirestoreService, public authService: AuthService, public router: Router) {}

	ngOnInit() {
		this.firestoreService.getContributionDocumentsAsObservable().subscribe(result => {
			this.partner = result as Partner[];
		});

		this.authService.getAuth().subscribe(auth => {
			if (auth) {
				this.isLoggedIn = true;
			} else {
				this.isLoggedIn = false;
			}
		});
	}

	onLogoutClick() {
		this.authService.logout();
		this.router.navigate(['/']);
	}
}
