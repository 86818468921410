import { AfterViewChecked, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirestoreService } from '../../../services/firestore.service';
import { Router } from '@angular/router';
import { firebase } from '../../../../environments/environment';

declare let paypal: any;
@Component({
	selector: 'app-pay',
	templateUrl: './pay.component.html',
	styleUrls: ['./pay.component.css'],
})
export class PayComponent implements AfterViewChecked {
	orderId!: string;
	addScript = false;
	finalAmount = 25;

	constructor(private activeRoute: ActivatedRoute, private firestoreService: FirestoreService, private router: Router) {
		//this.orderId = this.activeRoute.params['value'].id as string;
	}

	paypalClientCfg = {
		style: {
			shape: 'pill',
			color: 'gold',
			layout: 'vertical',
			label: 'paypal',
		},
		createOrder: (data: any, actions: { order: { create: (arg0: { purchase_units: { amount: { value: string } }[] }) => any } }) => {
			return actions.order.create({
				purchase_units: [
					{
						amount: {
							value: '25.00',
						},
					},
				],
			});
		},
		onError: (err: any) => {
			this.handleError();
		},
		onApprove: (data: any, actions: { order: { capture: () => Promise<any> } }) => {
			return actions.order.capture().then(details => {
				this.updateOrder(details.payer);
				this.handleSuccess();
			});
		},
	};

	ngAfterViewChecked(): void {
		if (!this.addScript) {
			this.addPaypalScript().then(() => {
				paypal.Buttons(this.paypalClientCfg).render('#paypal-button-container');
			});
		}
	}

	addPaypalScript() {
		this.addScript = true;
		return new Promise((resolve, reject) => {
			const scripttagElement = document.createElement('script');
			scripttagElement.src = 'https://www.paypal.com/sdk/js?client-id=' + firebase.PayPal_Client_Id + '&currency=USD';
			scripttagElement.onload = resolve;
			document.body.appendChild(scripttagElement);
		});
	}

	updateOrder(paypal: any) {
		const paypalOrder = { paypal };
		this.firestoreService.updateDocument('pie-orders', this.orderId, paypalOrder);
	}

	handleError() {
		this.router.navigate(['/failure']);
	}

	handleSuccess() {
		this.router.navigate(['/success']);
	}
}
