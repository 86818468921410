<mat-card class="material-card mt-1">
  <mat-card-header>
    <div mat-card-avatar class="warningicon"></div>
    <mat-card-title>Bully Prevention Seminar</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p> We will address the issue of bullying and how to react as a bystander or as the victim yourself. The curriculum basis is from a program known as “Olweus Bullying Prevention” in which the
      bystander learns that they can become the hero. </p>
  </mat-card-content>
</mat-card>
<mat-card class="material-card mt-1">
  <mat-card-header>
    <div mat-card-avatar class="workicon"></div>
    <mat-card-title>Career Day</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p> One of our instructors will come by to inform the students about what it takes to have a career in the martial arts, including the skills and education needed to do the job, with props and
      interactive activities. </p>
  </mat-card-content>
</mat-card>
<mat-card class="material-card mt-1">
  <mat-card-header>
    <div mat-card-avatar class="cakeicon"></div>
    <mat-card-title>Festival Booth</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p> We will run a board-breaking game booth in which children will be able to break plastic boards to win prizes while learning about the concept of mass and its involvement in power. Festival
      booths are a popular activity during Science Fairs or Science Nights. </p>
  </mat-card-content>
</mat-card>
<mat-card class="material-card mt-1">
  <mat-card-header>
    <div mat-card-avatar class="moneyicon"></div>
    <mat-card-title>Fundraising Packages</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p> Yongsa Martial Arts will fund fitness packages for you to sell. The package consists of martial arts lessons and a uniform/belt valued at $125. You may sell them at whatever price point you
      prefer - most organizations choose an average of $25 each and get to keep 100% of the proceeds. Flyers and posters to help promote the fundraiser will be delivered to the school as well. </p>
  </mat-card-content>
</mat-card>
<mat-card class="material-card mt-1">
  <mat-card-header>
    <div mat-card-avatar class="cameraicon"></div>
    <mat-card-title>Green Screen Photo Booth</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p> We are happy to use our cameras, lights, and green screen booth to set up a green screen photo shoot for your next event. We’ll superimpose the subjects onto background images you help select.
      Kids, parents, and teachers always enjoy this activity. </p>
  </mat-card-content>
</mat-card>
<mat-card class="material-card mt-1">
  <mat-card-header>
    <div mat-card-avatar class="sportsicon"></div>
    <mat-card-title>PE Coach for the Day</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p> We will send volunteers from Yongsa Martial Arts to teach a one-time martial arts-style fitness class so the PE teacher will be able to sit back and relax for the day while they incorporate
      values like respect and confidence to help improve the behavior of the class. </p>
  </mat-card-content>
</mat-card>
<mat-card class="material-card mt-1">
  <mat-card-header>
    <div mat-card-avatar class="peopleicon"></div>
    <mat-card-title>Special Events</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p> If you need a mascot appearance, event volunteers or chaperone. We’re here to help with whatever additional needs you have! </p>
  </mat-card-content>
</mat-card>
<mat-card class="material-card mt-1">
  <mat-card-header>
    <div mat-card-avatar class="memoryicon"></div>
    <mat-card-title>STEM Workshop</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p> Science, Technology, Engineering and Math all come together through our “Universal Principles of Sports Science” workshop in which we explore principles such as torque, speed, acceleration,
      leverage, power, and how to view the body in motion from a scientific perspective. </p>
  </mat-card-content>
</mat-card>
<mat-card class="material-card mt-1">
  <mat-card-header>
    <div mat-card-avatar class="offer"></div>
    <mat-card-title>Yongsa Trial Offers</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p> As a partner in education with the {{ partner[0].foundation }}, Yongsa has trial offers available to students at your school. The trial offers are essentially the same as our standard trial
      offer, but a portion of the proceeds goes to the {{ partner[0].foundation }}. </p>
  </mat-card-content>
</mat-card>