import { Injectable } from '@angular/core';
import { addDoc, collection, CollectionReference, collectionSnapshots, deleteDoc, doc, Firestore, orderBy, query, updateDoc, where } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Partner } from '../models/app.model';

@Injectable({
	providedIn: 'root',
})
export class FirestoreService {
	host: string;
	constructor(private afs: Firestore) {
		this.host = window.location.hostname;
	}

	getContributionDocumentsAsObservable() {
		return collectionSnapshots<Partner>(
			query<Partner>(collection(this.afs, 'pie-partners') as CollectionReference<Partner>, where('name', '==', this.host), orderBy('timeIn', 'desc'))
		).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data() as Partner;
					data.id = a.id;
					return data;
				});
			})
		);
	}

	updateDocument(col: string, id: string, payload: any) {
		const ref = doc(this.afs, col, id);
		updateDoc(ref, payload);
		return payload;
	}

	async addDocument(col: string, payload: any) {
		const ref = collection(this.afs, col);
		const doc = await addDoc(ref, payload);
		return doc.id;
	}

	deleteDocument(col: string, id: string) {
		const ref = doc(this.afs, col, id);

		deleteDoc(ref);
	}
}
