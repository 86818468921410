import { Injectable } from '@angular/core';
import { Auth, authState, GoogleAuthProvider, signInWithCredential } from '@angular/fire/auth';
import { take } from 'rxjs/operators';
import { gapiSettings } from '../../environments/gapisettings';

declare let gapi: any;

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	public user$;

	constructor(public auth: Auth) {
		this.initClient();
		this.user$ = authState(this.auth).pipe(take(1));
	}

	initClient() {
		gapi.load('client', () => {
			gapi.client.init(gapiSettings.Cfg);
			gapi.client.load('people', 'v1');
		});
	}

	/************************************************************
    auth by gapi and pass tokin to firestore auth
  ************************************************************/
	async login() {
		const googleAuth = gapi.auth2.getAuthInstance();
		const googleUser = await googleAuth.signIn();
		const token = googleUser.getAuthResponse().id_token;
		const credential = GoogleAuthProvider.credential(token);

		return new Promise((resolve, reject) => {
			signInWithCredential(this.auth, credential).then(
				data => resolve(data),
				error => reject(error.message)
			);
		});
	}

	logout() {
		this.auth.signOut();
	}

	getAuth() {
		return authState(this.auth).pipe(take(1));
	}
}
