import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Contribution, Partner } from '../../models/app.model';
import { FirestoreService } from '../../services/firestore.service';

@Component({
	selector: 'app-impact',
	templateUrl: './impact.component.html',
	styleUrls: ['./impact.component.css'],
})
export class ImpactComponent implements OnInit, OnDestroy {
	private partnerSubscription!: Subscription;
	private contributionSubscription!: Subscription;

	public partner: Partner[] = [];
	public dollarsContributed: number = 0;
	public hoursContributed: number = 0;

	constructor(private firestoreService: FirestoreService) {}

	ngOnInit() {
		this.getImpact();
	}

	getImpact() {
		this.partnerSubscription = this.firestoreService.getContributionDocumentsAsObservable().subscribe(result => {
			this.partner = result as Partner[];

			/*
			this.contributionSubscription = this.firestoreService.getContributionDocumentsAsObservable().subscribe(result => {
				let totalDollars = 0;
				let totalHours = 0;

				result.forEach((doc: Contribution) => {
					totalDollars = totalDollars + doc.dollars;
					totalHours = totalHours + doc.hours;
				});
				this.dollarsContributed = totalDollars;
				this.hoursContributed = totalHours;
			});
			*/
		});
	}

	ngOnDestroy(): void {
		this.partnerSubscription.unsubscribe();
		this.contributionSubscription.unsubscribe();
	}
}
