<mat-card class="material-card mt-2">
    <mat-card-header>
        <div mat-card-avatar class="moneyicon"></div>
        <mat-card-title>Dollars Contributed</mat-card-title>
    </mat-card-header>
    <img mat-card-image class="mt-2" src="https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2Fimpact.png?alt=media&token=fd7c879e-ca47-48a5-ab91-abd9a6da0382"
        alt="impact">
    <mat-card-content>
        <p> Since becoming a partner, Yongsa has contributed ${{ dollarsContributed }} to the {{ partner[0].foundation }}. With that kind of cash you can buy {{ dollarsContributed / 400 |
            number:'1.0-0' }} Acer Aspire desktop computers, {{ dollarsContributed / 29 | number:'1.0-0' }} pencil sharpeners, {{ dollarsContributed / 9.5 | number:'1.0-0' }} reams of notebook paper,
            or {{ dollarsContributed / 60 | number:'1.0-0' }} disco balls. Rock on {{ partner[0].name }}! </p>
    </mat-card-content>
</mat-card>
<mat-card class="material-card mt-3">
    <mat-card-header>
        <div mat-card-avatar class="timeicon"></div>
        <mat-card-title>Hours Contributed</mat-card-title>
    </mat-card-header>
    <img mat-card-image class="mt-2" src="https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2Fsleaves.png?alt=media&token=7692e342-70eb-4844-ae9a-e353db5c1947"
        alt="impact">
    <mat-card-content>
        <p> At Yongsa, we are not afraid to roll up our sleeves. Since becoming a partner we have volunteered {{ hoursContributed }} hours at {{ partner[0].name }}. </p>
    </mat-card-content>
</mat-card>