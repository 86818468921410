<mat-card class="material-card mt-1">
    <mat-card-header>
        <div mat-card-avatar class="bugicon"></div>
        <mat-card-title>Error Response from Paypal</mat-card-title>
    </mat-card-header>
    <img mat-card-image class="mt-2"
        src="https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2Fshocked.png?alt=media&token=a12514b3-03c3-4c98-b5cd-7cf9bb6bcd38"
        alt="impact">
    <mat-card-content>
        <p>
            It doesn't happen often, but it does happen! We are sorry that it happened to you. We sent your order to
            Paypal, and they rejected it. Don't let that stop you. Give us a call at 678-381-8305, and we'll handle your
            order personally.
        </p>
    </mat-card-content>
</mat-card>