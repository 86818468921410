<section class="manageForm">
    <form fxLayout="column" fxLayoutAlign="center center" #f="ngForm" (ngSubmit)="onAdd(f, partner[0].name)">
        <mat-form-field>
            <mat-select ngModel name="type" placeholder="Type" required>
                <mat-option *ngFor="let statement of statements" [value]="statement.viewKey"> {{ statement.viewValue }} </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <input type="text" matInput placeholder="Description" ngModel name="description" required />
        </mat-form-field>
        <mat-form-field>
            <input matInput type="datetime-local" placeholder="Start Time" ngModel name="starttime" required />
        </mat-form-field>
        <mat-form-field>
            <input matInput type="datetime-local" placeholder="End Time" ngModel name="endtime" />
        </mat-form-field>
        <mat-form-field>
            <input matInput type="number" placeholder="dollars" ngModel name="dollars" required />
        </mat-form-field>
        <mat-form-field>
            <input matInput type="number" placeholder="hours" ngModel name="hours" required />
        </mat-form-field>
        <button type="submit" mat-raised-button color="primary">Add</button>
    </form>
</section>
<section fxLayoutAlign="center center" class="mt-5">
    <table>
        <thead>
            <tr>
                <th scope="col" style="width:50px;">Remove</th>
                <th scope="col" style="width:130px;">Type</th>
                <th scope="col" style="width:200px;">Description</th>
                <th scope="col" style="width:160px;">In</th>
                <th scope="col" style="width:80px;">Out</th>
                <th scope="col" style="width:60px; text-align:right;">Dollars</th>
                <th scope="col" style="width:60px; text-align:right;">Hours</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let contribution of contributions; index as i">
                <td>
                    <button mat-raised-button (click)="onRemove('pie-contributions', contribution.id)">
                        <i class="fa fa-trash"></i>
                    </button>
                </td>
                <td>{{ contribution.type }}</td>
                <td>{{ contribution.description }}</td>
                <td>{{ contribution.timeIn | date: "M/d/y h:mm a" }}</td>
                <td>{{ contribution.timeOut | date: "h:mm a" }}</td>
                <td style="text-align:right;">{{ contribution.dollars }}</td>
                <td style="text-align:right;">{{ contribution.hours }}</td>
            </tr>
        </tbody>
    </table>
</section>