<mat-card class="material-card mt-1">
    <mat-card-header>
        <!-- <div mat-card-avatar [ngClass]="partner[0]!.logo"></div> -->
        <div mat-card-avatar></div>
        <mat-card-title>Karate for {{ partner[0].name }} Fundraiser</mat-card-title>
    </mat-card-header>
    <img mat-card-image class="mt-2" src="https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/website%2Ftournaments.png?alt=media&token=262e3085-7ae8-45ed-bf8e-b03a2f6ed5b6" alt="fundraiser">
    <mat-card-content>
        <p> Yongsa Martial Arts is a Platinum Sponsor of the {{partner[0].foundation}}! In addition to our sponsorship, we are providing another fundraising opportunity exclusively for
            {{partner[0].name}} students. For only $25, your child can take five karate classes at the studio located on 3246 Atlanta Road across from Campbell Middle School. The offer includes a free
            uniform!</p>
        <p style="text-align: center; font-weight: bolder; font-size: x-large"> What you need to know! </p>
        <ul>
            <li> This offer is exclusively for {{partner[0].name}} students.</li>
            <li style="font-weight: bolder">ALL of the proceeds from this offer benefit the {{partner[0].foundation}}! </li>
            <li *ngIf="!isFundraiser"> The official kickoff date for the fundraiser is on {{partner[0].fundraiser?.kickoff | date: "MMM d, yyyy" }}. Around that date, an offer letter will be sent home
                with every student. However, you do not have to wait until the fundraiser to enjoy a good deal. We have an exclusive <a href={{partner[0].trialOffer}}>
                    <span style="font-weight: bolder; font-size: 1.1em">offer</span></a> for {{ partner[0].name }} students available on our website all year so you can get started earning your
                blackbelt today! </li>
            <li>Classes for students ages 7+ are held from 4:30 until 5:00 PM on Mondays and Wednesdays starting {{partner[0].fundraiser?.startDate | date: "MMM d, yyyy" }} until
                {{partner[0].fundraiser?.endDate | date: "MMM d, yyyy"}}. </li>
            <li>For students ages 4 to 6, classes are held from 4:30 until 5:00 PM on Tuesdays and Thursdays from {{partner[0].fundraiser?.startDate | date: "MMM d, yyyy"}} until
                {{partner[0].fundraiser?.endDate | date: "MMM d, yyyy"}}. </li>
            <li style="font-weight: bolder"> The registration deadline is {{partner[0].fundraiser?.deadline | date: "MMM d, yyyy"}}.</li>
            <li>Taekwondo is a sport. Like all sports, there is some risk of injury. For this reason, the participant and the guardian need to attend an orientation (approximately 30 minutes). At the
                orientation, we will explain any risk, and you will need to sign a sports waiver. Additionally, a member of our staff will size each student for a uniform, which we will issue at their
                first class. The orientation dates are shown below: <div style="padding: 1em">
                    <div *ngFor="let orientation of partner[0].fundraiser?.orientations" style="text-align: center"> {{ orientation | date: "MMM d, yyyy, h:mm a"}}</div>
                </div>
            </li>
        </ul>
    </mat-card-content>
    <mat-card-actions style="text-align: center">
        <button mat-raised-button (click)="openWindowSameTab('offer')" [disabled]="!isFundraiser" *ngIf="!isFundraiser">Get Offer</button>
        <button mat-raised-button (click)="openWindowSameTab('offer')" [ngStyle]="partner[0].css" *ngIf="isFundraiser">Get Offer</button>
    </mat-card-actions>
</mat-card>