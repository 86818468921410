import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-afterschool',
	templateUrl: './afterschool.component.html',
	styleUrls: ['./afterschool.component.css']
})
export class AfterschoolComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
