<nav class="navbar navbar-expand-lg navbar-dark menu-bar" [ngStyle]="partner[0].css">
  <a routerLink="" class="navbar-brand" href="#"></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div id="navbarNavDropdown" class="navbar-collapse collapse ">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" routerLink="/about">About</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/impact">Impact</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/fundraiser">Fundraiser</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/atyourservice">@YourService</a>
      </li>
      <li *ngIf="partner[0].name==='Teasley'" class="nav-item">
        <a class="nav-link" routerLink="/afterschool">After School</a>
      </li>
      <li *ngIf="isLoggedIn" class="nav-item">
        <a class="nav-link" routerLink="/manage">Manage</a>
      </li>
      <li *ngIf="isLoggedIn" class="nav-item">
        <a href="#" (click)="onLogoutClick()" class="nav-link">Logout</a>
      </li>
    </ul>
  </div>
</nav>