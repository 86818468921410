<mat-card class="material-card mt-1">
    <div style="padding: 5em">
        <p>
            You can now make payment through PayPal, the most trusted name in Internet shopping.
        </p>
        <p>
            The total amount of your purchase is $25. Payments are not refundable. However, they are transferable.
        </p>
        <div id="paypal-button-container"></div>
    </div>
</mat-card>