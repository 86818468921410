<mat-card class="material-card mt-2">
    <mat-card-header>
        <div mat-card-avatar class="paymenticon"></div>
        <mat-card-title>Program Cost</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ul>
            <li>Part-time (up to 3 days per week) - $40/week</li>
            <li>Full-time (up to 5 days per week) - $60/week</li>
            <li>Enrollment requires participation in a recurring payment program. Payments will be processed every
                Monday.</li>
            <li>Yongsa Martial Arts must be informed at least two weeks before the regularly scheduled payment to pause
                or cancel enrollment in the program.</li>
        </ul>
    </mat-card-content>
</mat-card>
<mat-card class="material-card mt-2">
    <mat-card-header>
        <div mat-card-avatar class="moneyicon"></div>
        <mat-card-title>Enrollment Cost</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ul>
            <li>Enrollment - $250 one time cost includes:</li>
            <li>Two uniforms</li>
            <li>All sparring gear (mouthguard, headgear and face shield, chest protector, gloves, foot protectors)</li>
            <li>Four training weapons</li>
            <li>One rebreakable board</li>
            <li>One gear board</li>
        </ul>
    </mat-card-content>

</mat-card>
<mat-card class="material-card mt-2">
    <mat-card-header>
        <div mat-card-avatar class="warningicon"></div>
        <mat-card-title>Capacity</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ul>
            <li>Program is limited to 13 students per day.</li>
            <li>Enrollment is on a first-come-first-serve basis.</li>
        </ul>
    </mat-card-content>
</mat-card>
<mat-card class="material-card mt-2">
    <mat-card-header>
        <div mat-card-avatar class="transportationicon"></div>
        <mat-card-title>Transportation</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ul>
            <li>Students will be transported from their school via 14 passenger van starting at 2:15 PM.</li>
            <li>Yongsa will maintain a list of all students who should be present during transportation.</li>
            <li>Students must wear seat belts during transportation.</li>
        </ul>
    </mat-card-content>
</mat-card>
<mat-card class="material-card mt-2">
    <mat-card-header>
        <div mat-card-avatar class="infoicon"></div>
        <mat-card-title>Orientation</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ul>
            <li>Yongsa will hold an orientation for interested parents on Saturday, May 2, at 2:30 PM.</li>
            <li>Please register for the orientation by phone at 678-381-8305 or by email at <a
                    href="mailto: info@yongsa.net?Subject=Teasley%20After%20School%20Martial%20Arts"
                    target="_top">info@yongsa.net</a></li>
            <li>Parents that enroll at the orientation will receive $50 off the enrollment fee.</li>
        </ul>
    </mat-card-content>
</mat-card>