import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FirestoreService } from '../../../services/firestore.service';
import { Partner } from '../../../models/app.model';
import { Subscription } from 'rxjs';
@Component({
	selector: 'app-offer',
	templateUrl: './offer.component.html',
	styleUrls: ['./offer.component.css'],
})
export class OfferComponent implements OnInit, OnDestroy {
	private partnerSubscription!: Subscription;

	public FormGroup!: FormGroup;
	public canEdit = true;
	public order!: Promise<any>;
	public orderId!: string;
	public targetInput = 'input0';

	public partner: Partner[] = [];

	constructor(private router: Router, private firestoreService: FirestoreService) {}

	ngOnInit() {
		this.partnerSubscription = this.firestoreService.getContributionDocumentsAsObservable().subscribe(result => {
			this.partner = result as Partner[];
		});
	}

	onAdd(f: any) {
		if (f.value.phone.phone === '') return;
		this.canEdit = false;
		const data = {
			email: f.value.email.email,
			fullName: f.value.fullname.fullname,
			birthday: f.value.birthday.birthday,
			street: f.value.street.street,
			postal: f.value.postal.postal,
			phone: f.value.phone.phone,
			orientation: f.value.orientation.orientation,
		};
		this.order = this.firestoreService.addDocument('pie-orders', data);
		this.order.then(val => {
			this.orderId = val;
			this.router.navigate(['/pay', this.orderId]);
		});
	}

	ngOnDestroy(): void {
		this.partnerSubscription.unsubscribe();
	}
}
