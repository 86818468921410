import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from '../components/about/about.component';
import { AfterschoolComponent } from '../components/afterschool/afterschool.component';
import { AtYourServiceComponent } from '../components/atyourservice/atyourservice.component';
import { FailureComponent } from '../components/fundraiser/failure/failure.component';
import { FundraiserComponent } from '../components/fundraiser/fundraiser.component';
import { OfferComponent } from '../components/fundraiser/offer/offer.component';
import { PayComponent } from '../components/fundraiser/pay/pay.component';
import { SuccessComponent } from '../components/fundraiser/success/success.component';
import { ImpactComponent } from '../components/impact/impact.component';
import { LoginComponent } from '../components/login/login.component';
import { ManageComponent } from '../components/manage/manage.component';
import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [
	{ path: '', component: AboutComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'about', component: AboutComponent },
	{ path: 'impact', component: ImpactComponent },
	{ path: 'fundraiser', component: FundraiserComponent },
	{ path: 'offer', component: OfferComponent },
	{ path: 'success', component: SuccessComponent },
	{ path: 'failure', component: FailureComponent },
	{ path: 'pay/:id', component: PayComponent },
	{ path: 'success', component: SuccessComponent },
	{ path: 'atyourservice', component: AtYourServiceComponent },
	{ path: 'afterschool', component: AfterschoolComponent },
	{ path: 'manage', component: ManageComponent, canActivate: [AuthGuard] },
	{ path: '**', redirectTo: '/' },
];
@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
	providers: [AuthGuard],
})
export class AppRoutingModule {}
