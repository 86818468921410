import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Partner } from '../../models/app.model';
import { FirestoreService } from '../../services/firestore.service';

export interface KeyValuePair {
	viewKey: string;
	viewValue: string;
}

@Component({
	selector: 'app-manage',
	templateUrl: './manage.component.html',
	styleUrls: ['./manage.component.css'],
})
export class ManageComponent implements OnInit, OnDestroy {
	private partnerSubscription!: Subscription;
	private contributionSubscription!: Subscription;

	public partner: Partner[] = [];
	public isLoggedIn: boolean = false;
	public contributions!: any;

	public names: KeyValuePair[] = [
		{ viewKey: 'Campbell Middle School', viewValue: 'Campbell Middle School' },
		{ viewKey: 'Nickajack', viewValue: 'Nickajack' },
		{ viewKey: 'Teasley', viewValue: 'Teasley' },
	];

	public statements: KeyValuePair[] = [
		{ viewKey: 'Bully Prevention Seminar', viewValue: 'Bully Prevention Seminar' },
		{ viewKey: 'Career Day', viewValue: 'Career Day' },
		{ viewKey: 'Donation', viewValue: 'Donation' },
		{ viewKey: 'Festival Booth', viewValue: 'Festival Booth' },
		{ viewKey: 'Fundraising', viewValue: 'Fundraising' },
		{ viewKey: 'Photo Booth', viewValue: 'Photo Booth' },
		{ viewKey: 'Special Event', viewValue: 'Special Event' },
		{ viewKey: 'Sponsorship', viewValue: 'Sponsorship' },
		{ viewKey: 'STEM Workshop', viewValue: 'STEM Workshop' },
		{ viewKey: 'Trial Offers', viewValue: 'Trials Offers' },
	];

	constructor(private firestoreService: FirestoreService) {}

	ngOnInit() {
		this.partnerSubscription = this.firestoreService.getContributionDocumentsAsObservable().subscribe(result => {
			this.partner = result as Partner[];

			this.contributionSubscription = this.firestoreService.getContributionDocumentsAsObservable().subscribe(result => {
				this.contributions = result;
			});
		});
	}

	onAdd(f: any, partner: string | null) {
		const data = {
			description: f.form.value.description,
			name: partner,
			type: f.form.value.type,
			timeIn: f.form.value.starttime,
			timeOut: f.form.value.endtime,
			dollars: f.form.value.dollars,
			hours: f.form.value.hours,
		};
		this.firestoreService.addDocument('pie-contributions', data);
	}

	onRemove(col: string, doc: string) {
		this.firestoreService.deleteDocument(col, doc);
	}

	ngOnDestroy(): void {
		this.partnerSubscription.unsubscribe();
		this.contributionSubscription.unsubscribe();
	}
}
