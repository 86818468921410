import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private authService: AuthService) {}

	canActivate(): Observable<boolean> {
		return this.authService.user$.pipe(
			map(auth => {
				if (!auth) {
					return false;
				} else {
					return true;
				}
			})
		);
	}
}
