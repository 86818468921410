import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FirestoreService } from 'src/app/services/firestore.service';
import { Partner } from '../../models/app.model';

@Component({
	selector: 'app-about',
	templateUrl: './about.component.html',
	styleUrls: ['./about.component.css'],
})
export class AboutComponent implements OnInit, OnDestroy {
	private partnerSubscription!: Subscription;

	public partner: Partner[] = [];

	constructor(public firestoreService: FirestoreService) {}

	ngOnInit() {
		this.partnerSubscription = this.firestoreService.getContributionDocumentsAsObservable().subscribe(result => {
			this.partner = result as Partner[];
		});
	}
	openWindowNewTab(url: string | null | undefined): void {
		if (!url) url = '';
		window.open(url);
	}

	openWindowSameTab(url: string | null | undefined) {
		if (!url) url = '';
		window.location.replace(url);
	}

	ngOnDestroy(): void {
		this.partnerSubscription.unsubscribe();
	}
}
