<mat-card class="material-card mt-1">
    <mat-card-header>
        <div mat-card-avatar class="checkedicon"></div>
        <mat-card-title>We are all done here!</mat-card-title>
    </mat-card-header>
    <img mat-card-image class="mt-2"
        src="https://firebasestorage.googleapis.com/v0/b/partnerineducation-0.appspot.com/o/rocks%2Fexcited.png?alt=media&token=a38d07d9-fc5a-4034-83ad-3e619543703e"
        alt="impact">
    <mat-card-content>
        <p>
            We are so happy that we cannot stop smiling. We are looking forward to seeing you at orientation.
        </p>
    </mat-card-content>
</mat-card>