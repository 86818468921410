<mat-card class="material-card mt-1">
    <form #f="ngForm" (ngSubmit)="onAdd(f)">
        <!-- <mat-vertical-stepper (selectionChange)="onChange($event)" linear> -->
        <mat-vertical-stepper linear>
            <mat-step [editable]="canEdit" ngModelGroup="email" [stepControl]="f.controls.email">
                <ng-template matStepLabel>Fill out your email address</ng-template>
                <mat-form-field>
                    <input id="input0" id="input" type="email" matInput placeholder="Email" ngModel name="email" required email #email="ngModel" />
                    <span class="help-block" *ngIf="!email.valid && email.touched">&nbsp;&nbsp; Please enter a valid email!</span>
                </mat-form-field>
                <div>
                    <button type="button" mat-button matStepperNext color="primary">Next</button>
                </div>
            </mat-step>
            <mat-step [editable]="canEdit" ngModelGroup="fullname" [stepControl]="f.controls.fullname">
                <ng-template matStepLabel>Fill out the participant's name</ng-template>
                <mat-form-field>
                    <input id="input1" type="text" matTooltip="This is the person that will be taking classes" matInput placeholder="First and Last Name" ngModel name="fullname" required>
                </mat-form-field>
                <div>
                    <button type="button" mat-button matStepperNext color="primary">Next</button>
                </div>
            </mat-step>
            <mat-step [editable]="canEdit" ngModelGroup="birthday" [stepControl]="f.controls.birthday">
                <ng-template matStepLabel>Fill out the participant's birthday</ng-template>
                <mat-form-field>
                    <input id="input2" type="date" matInput placeholder="Participant's Birthday" ngModel name="birthday" required>
                </mat-form-field>
                <div>
                    <button type="button" mat-button matStepperNext>Next</button>
                </div>
            </mat-step>
            <mat-step [editable]="canEdit" ngModelGroup="street" [stepControl]="f.controls.street">
                <ng-template matStepLabel>Fill out the street address</ng-template>
                <mat-form-field>
                    <input id="input3" type="text" matTooltip="No city or state is required" matInput placeholder="Street" ngModel name="street" required />
                </mat-form-field>
                <div>
                    <button type="button" mat-button matStepperNext color="primary">Next</button>
                </div>
            </mat-step>
            <mat-step [editable]="canEdit" ngModelGroup="postal" [stepControl]="f.controls.postal">
                <ng-template matStepLabel>Fill out the postal code</ng-template>
                <mat-form-field>
                    <input id="input4" type="text" matTooltip="No city or state is required" matInput placeholder="Postal" ngModel name="postal" required />
                </mat-form-field>
                <div>
                    <button type="button" mat-button matStepperNext color="primary">Next</button>
                </div>
            </mat-step>
            <mat-step [editable]="canEdit" ngModelGroup="orientation" [stepControl]="f.controls.orientation">
                <mat-form-field>
                    <ng-template matStepLabel>Select an orientation date</ng-template>
                    <mat-select id="input5" ngModel name="orientation" placeholder="orientation" required>
                        <mat-option *ngFor="let orientation of partner[0].fundraiser?.orientations" [value]="orientation"> {{ orientation | date: "EEEE, MMM d, yyyy, h:mm a"}} </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-step>
            <mat-step [editable]="canEdit" ngModelGroup="phone" [stepControl]="f.controls.phone">
                <ng-template matStepLabel>Fill out your phone number</ng-template>
                <mat-form-field>
                    <input id="input6" [disabled]="!canEdit" type="tel" matInput placeholder="Phone" ngModel name="phone" required />
                </mat-form-field>
                <div>
                    <button type="submit" mat-raised-button matStepperNext color="primary" [disabled]="!f.valid">Submit</button>
                </div>
            </mat-step>
        </mat-vertical-stepper>
    </form>
</mat-card>