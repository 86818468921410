<mat-card class="material-card mt-1">
  <mat-card-header>
    <div mat-card-avatar class="yongsaheaderimage"></div>
    <mat-card-title>Yongsa Martial Arts Partnership in Education</mat-card-title>
  </mat-card-header>
  <img mat-card-image class="mt-2" src="https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/lifeskills%2Fbelief.png?alt=media&token=ce4874bf-482d-4b90-a361-d583a77429e7" alt="lifeskills" />
  <mat-card-content>
    <p> The Yongsa mission statement is “Enriching lives through the practice of martial arts.” This is more than words to us. It is a cornerstone to our business values and why we take community
      service so seriously. In addition to the financial contributions Yongsa makes to the {{ partner[0].foundation }}, we also have an array of standard services we provide for free to our partners
      in education. Check out our @YourService page or contact us to schedule an activity at your school. </p>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button (click)="openWindowSameTab('fundraiser')" [ngStyle]="partner[0].css" style="margin: 10px;"> Annual Fundraiser </button>
    <button mat-raised-button (click)="openWindowSameTab('atyourservice')" [ngStyle]="partner[0].css" style="margin: 10 px;">@YourService</button>
    <button *ngIf="partner[0].name==='Teasley'" mat-raised-button (click)="openWindowSameTab('afterschool')" [ngStyle]="partner[0].css" style="margin: 10 px;">After School Martial Arts</button>
    <button mat-raised-button (click)="openWindowNewTab('https://yongsa.net/contact-us')" [ngStyle]="partner[0].css" style="margin: 10 px;">Contact Us</button>
    <button mat-raised-button style="color: black; background-color: gold; border-color: black; border-style: solid; border-width: 1px; margin: 10px;"
      (click)="openWindowNewTab('https://yongsa.net')">Yongsa Website</button>
  </mat-card-actions>
</mat-card>
<mat-card class="material-card mt-1">
  <mat-card-header>
    <!-- <div mat-card-avatar [ngClass]="partner[0].logo"></div> -->
    <div mat-card-avatar></div>
    <mat-card-title>{{ partner[0].name }} Rocks</mat-card-title>
    <mat-card-subtitle>{{ partner[0].slogan }}!</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <p> It's true! {{ partner[0].name }} Rocks! To help keep it rocking, Yongsa Martial Arts has become a Platinum Sponsor of the {{ partner[0].foundation }}. This site is dedicated to sharing
      information about the Yongsa Partnership in Education Program and our commitment to the Teachers, Staff, and Students at {{ partner[0].name }}. For more information on the {{
      partner[0].foundation }}, check out their web site. </p>
  </mat-card-content>
  <mat-card-actions>
    <button id="partnerButton" mat-raised-button (click)="openWindowNewTab(partner[0].website)" [ngStyle]="partner[0].css"> {{ partner[0].foundation }} </button>
  </mat-card-actions>
</mat-card>