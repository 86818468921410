import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FirestoreService } from 'src/app/services/firestore.service';
import { Partner } from '../../models/app.model';

@Component({
	selector: 'app-fundraiser',
	templateUrl: './fundraiser.component.html',
	styleUrls: ['./fundraiser.component.css'],
})
export class FundraiserComponent implements OnInit, OnDestroy {
	private partnerSubscription!: Subscription;

	public partner: Partner[] = [];
	public isFundraiser = false;

	constructor(public firestoreService: FirestoreService) {}

	ngOnInit() {
		this.partnerSubscription = this.firestoreService.getContributionDocumentsAsObservable().subscribe(result => {
			this.partner = result as Partner[];
			const today = new Date();
			/* 
			const kickoff = new Date(this.partner.fundraiser.kickoff);
			const deadline = new Date(this.partner.fundraiser.deadline);
			if (today >= kickoff && today <= deadline) {
				this.isFundraiser = true;
			} else {
				this.isFundraiser = false;
			}
			*/
		});
	}

	openWindowNewTab(url: string) {
		window.open(url);
	}

	openWindowSameTab(url: string) {
		window.location.replace(url);
	}

	ngOnDestroy(): void {
		this.partnerSubscription.unsubscribe();
	}
}
